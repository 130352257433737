import type { Placement } from '@popperjs/core';
import { createContext, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { useClickOutside } from '@sb/ui/hooks';

import styles from './Dropdown.module.css';

interface DropdownProps {
  children: React.ReactNode;
  className?: string;
  content: React.ReactNode;
  isOpen: boolean;
  /** Set this to `true` if you don't want the active element to lose focus when clicking */
  preventDefaultOnMouseDown?: boolean;
  placement?: Placement | undefined;
  onClose: () => void;
  offset?: number;
  /** If this value changes and is truthy, popper forceUpdate will be called */
  popperForceUpdate?: any;
  'data-testid'?: string;
}

/** Override popperjs fallbacks for some placements, so longer dropdowns get more space to fit on the page */
const FALLBACK_PLACEMENTS: { [placement in Placement]?: Placement[] } = {
  'bottom-end': ['top-end', 'left-start', 'right-start'],
  'bottom-start': ['top-start', 'right-start', 'left-start'],
  bottom: ['top', 'right-start', 'left-start'],
  'top-end': ['bottom-end', 'left-end', 'right-end'],
  'top-start': ['bottom-start', 'right-end', 'left-end'],
  top: ['bottom', 'right-end', 'left-end'],
};

const DropdownContext = createContext<HTMLElement | null>(null);

const Dropdown = ({
  children,
  className,
  content,
  isOpen,
  preventDefaultOnMouseDown,
  placement = 'bottom-end',
  onClose,
  offset = 3,
  popperForceUpdate,
  'data-testid': testID,
}: DropdownProps) => {
  const parentContainerElement = useContext(DropdownContext) ?? document.body;

  // The container holds this popup _plus any descendant popups_ (e.g. sub menus).
  // Descendants access this container through `DropdownContext`.
  // This lets us use the DOM structure for the clickOutside hook to work as expected.
  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null);

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  useClickOutside([containerElement, referenceElement], onClose);

  const skidding =
    placement.startsWith('bottom') || placement.startsWith('top') ? 1 : -2;

  const {
    styles: popperStyles,
    attributes,
    forceUpdate,
  } = usePopper(referenceElement, popperElement, {
    placement,
    strategy:
      'fixed' /* avoids dropdown triggering overflow on document.body */,
    modifiers: [
      {
        name: 'flip',
        options: { fallbackPlacements: FALLBACK_PLACEMENTS[placement] },
      },
      { name: 'offset', options: { offset: [skidding, offset] } },
      { name: 'preventOverflow', options: { padding: 10 } },
      { name: 'eventListeners', enabled: isOpen },
    ],
  });

  useEffect(() => {
    if (popperForceUpdate && forceUpdate) {
      forceUpdate();
    }
  }, [popperForceUpdate, forceUpdate]);

  const handleMouseDown: React.MouseEventHandler | undefined =
    preventDefaultOnMouseDown ? (e) => e.preventDefault() : undefined;

  // close the popper if user clicks on an enabled button inside it
  // (you can override this by adding `event.stopPropagation()` inside your button click handler)
  const handleClickPopper: React.MouseEventHandler = (event) => {
    let target: EventTarget | null = event.target; // eslint-disable-line prefer-destructuring

    while (target) {
      if (target instanceof HTMLButtonElement) {
        if (!target.disabled) onClose();
        target = null;
      } else if (target instanceof Element) {
        target = target.parentElement;
      } else {
        target = null;
      }
    }
  };

  /* eslint-disable jsx-a11y/no-static-element-interactions */

  return (
    <DropdownContext.Provider value={containerElement}>
      <div
        ref={setReferenceElement}
        className={className}
        onMouseDown={handleMouseDown}
        data-testid={testID && `${testID}--dropdown`}
      >
        {children}
      </div>

      {createPortal(
        <div ref={setContainerElement} className={styles.popperContainer}>
          <div
            ref={setPopperElement}
            className={styles.dropdownContent}
            onClick={handleClickPopper}
            style={popperStyles.popper}
            onMouseDown={handleMouseDown}
            data-testid={testID && `${testID}--options`}
            {...attributes.popper}
            hidden={!isOpen}
          >
            {content}
          </div>
        </div>,
        parentContainerElement,
      )}
    </DropdownContext.Provider>
  );
};

export default Dropdown;
