import type { Robot, Unsubscribe } from '@sb/types';

import { onGetRobots } from './onGetRobots';

export function onGetRobot(
  robotID: string | undefined,
  onSnapshot: (robots: Robot.ConvertedResponse | null) => void,
): Unsubscribe {
  return onGetRobots(
    { robotID, isDefault: robotID ? undefined : true },
    (robots) => onSnapshot(robots[0] ?? null),
  );
}
