import { clamp, pick } from 'lodash';

import type {
  DeviceCommand,
  DeviceConfiguration,
  DeviceState,
} from '@sb/integrations/device';
import { ModbusFunctionCode } from '@sb/integrations/modbus/constants';
import { handleModbusRegisterRequest } from '@sb/integrations/modbus/utility';
import { OR_2FG7_GRIP_KINDS } from '@sb/integrations/OnRobot2FG7';
import type {
  DeviceSimulator,
  SimulatorConstructorArgs,
} from '@sb/integrations/simulator';
import type { AbstractLogger } from '@sb/logger';
import { Logger } from '@sb/logger';
import { setBit, ON, OFF, wait } from '@sb/utilities';

import type { OnRobot3FG15Command } from '..';
import type { OR3FG15CommandKind } from '../constants';
import {
  OR_3FG15_SOURCE_OFFSET_METERS,
  isValidOR3FG16FingerPostion,
  OR3FG15_CONTROL_COMMAND,
  OR3FG15_REGISTER,
  OR3FG15_TARGET_ADDRESS,
  OR_3FG15_DEFAULT_FINGER_LENGTH,
  OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
  OR_3FG15_FINGER_POSITION_DEFAULT,
  OR_3FG15_FORCE_APPLIED_FRACTION_MIN,
  OR_3FG15_FORCE_NEWTONS_MAX,
  OR_3FG15_FORCE_NEWTONS_MIN,
  OR_3FG15_GRIP_KINDS,
  OR_3FG15_TARGET_FORCE_DEFAULT,
  OR_3FG15_FINGER_ANGLE_MAX,
} from '../constants';
import type { OnRobot3FG15State } from '../State';
import {
  calculateOR3FG15FingerAngle,
  calculateTargetGripDiameterRawMeters,
  calculateOR3FG15DiameterFromFingerAngle,
  calculateOR3FG15DiameterRange,
  calculateOR3FG15ActuationCommandKind,
} from '../utils';

/* copied from urdf - rad/ms */
const OR_3FG15_VELOCITY = 0.520366684391 / 1000;

export class OnRobot3FG15Simulator
  implements DeviceSimulator<OnRobot3FG15State>
{
  public kind = 'OnRobot3FG15' as const;

  private state: Omit<
    OnRobot3FG15State,
    'diameterMeters' | 'forceAppliedFraction'
  > = {
    kind: 'OnRobot3FG15',
    isBusy: false,
    isConnected: true,
    isGripDetected: false,
    isForceGripDetected: false,
    isCalibrationOk: true,
    gripKind: 'inward',
    fingerAngle: OR_3FG15_FINGER_ANGLE_MAX,
    fingerPosition: OR_3FG15_FINGER_POSITION_DEFAULT,
    fingerLength: OR_3FG15_DEFAULT_FINGER_LENGTH,
    fingertipDiameter: OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
    forceAppliedNewtons: 10,
    targetForceNewtons: OR_3FG15_TARGET_FORCE_DEFAULT,
  };

  public updateConfig(newConfig: DeviceConfiguration) {
    if (newConfig.kind !== 'OnRobot3FG15') {
      throw new Error(`Invalid configuration type: ${newConfig.kind}`);
    }

    if (newConfig.fingerConfiguration) {
      this.state.fingerPosition =
        newConfig.fingerConfiguration.mountingPosition;

      this.state.fingertipDiameter =
        newConfig.fingerConfiguration.offsetInMeters;

      this.state.fingerLength = newConfig.fingerConfiguration.lengthInMeters;
    }
  }

  private logger: AbstractLogger;

  public constructor(args: SimulatorConstructorArgs) {
    this.logger = args.logger ?? new Logger();
    this.logger.label = 'OnRobot3FG15Simulator';
    this.logger.enableConsole();
  }

  public getState(): OnRobot3FG15State {
    return {
      ...this.state,
      diameterMeters: this.getDiameterMeters(),
      forceAppliedFraction: this.getForceAppliedFraction(),
    };
  }

  public setState(state: Partial<DeviceState>) {
    if (state.kind !== 'OnRobot3FG15') return;

    if (state.isConnected !== undefined) {
      this.state.isConnected = state.isConnected;
    }

    if (state.gripKind !== undefined) {
      this.state.gripKind = state.gripKind;
    }

    if (state.diameterMeters !== undefined) {
      this.state.fingerAngle = calculateOR3FG15FingerAngle({
        fingerPosition: this.state.fingerPosition,
        fingerLength: this.state.fingerLength,
        fingertipDiameter: this.state.fingertipDiameter,
        gripKind: this.state.gripKind,
        diameter: state.diameterMeters,
      });
    } else if (state.fingerAngle !== undefined) {
      this.state.fingerAngle = state.fingerAngle;
    }

    if (state.forceAppliedNewtons !== undefined) {
      this.state.forceAppliedNewtons = state.forceAppliedNewtons;
    }

    if (state.targetForceNewtons !== undefined) {
      this.state.targetForceNewtons = state.targetForceNewtons;
    }
  }

  public async actuate(command: DeviceCommand) {
    if (command.kind !== 'OnRobot3FG15Command') {
      throw new Error(
        `Cannot actuate simulated gripper with this kind of command: ${JSON.stringify(
          command,
        )}`,
      );
    }

    const actuationKind: OR3FG15CommandKind =
      calculateOR3FG15ActuationCommandKind(command);

    const fingerConfiguration = this.getFingerConfiguration();

    const offsetMeters = command.targetDiameter;

    const rawMeters = calculateTargetGripDiameterRawMeters(
      command,
      fingerConfiguration,
    );

    const commandDiameter =
      actuationKind === 'grip' || actuationKind === 'flexGrip'
        ? offsetMeters
        : rawMeters;

    const newCommand: OnRobot3FG15Command = {
      ...command,
      waitForGripToContinue: actuationKind === 'grip',
      isFlexGrip: actuationKind === 'flexGrip',
      targetDiameter: commandDiameter,
    };

    await this.animateToTarget(newCommand);
  }

  private async animateToTarget(command: OnRobot3FG15Command) {
    const fingertipDiameter =
      command.waitForGripToContinue || command.isFlexGrip
        ? this.state.fingertipDiameter
        : 0;

    // calculate target angle based on command
    const targetAngle = calculateOR3FG15FingerAngle({
      fingerPosition: this.state.fingerPosition,
      fingerLength: this.state.fingerLength,
      fingertipDiameter,
      diameter: command.targetDiameter,
      gripKind: command.gripKind,
    });

    this.state.gripKind = command.gripKind;

    this.state.targetForceNewtons = command.targetForce;

    const startingAngle = this.state.fingerAngle;

    const TIME_INTERVAL_MS = 50;

    const incrementRadians = (() => {
      return startingAngle > targetAngle
        ? -OR_3FG15_VELOCITY * TIME_INTERVAL_MS
        : OR_3FG15_VELOCITY * TIME_INTERVAL_MS;
    })();

    this.state.isBusy = true;

    // interpolate angle and iterate through interpolation on an interval
    for (
      let ii = incrementRadians;
      Math.abs(ii) < Math.abs(targetAngle - startingAngle);
      ii += incrementRadians
    ) {
      this.state.fingerAngle = startingAngle + ii;

      await wait(TIME_INTERVAL_MS);

      if (!this.state.isBusy) {
        throw new Error('Robot stopped mid-actuation');
      }
    }

    this.state.fingerAngle = targetAngle;

    // Mock force grip
    if (command.waitForGripToContinue || command.isFlexGrip) {
      await wait(100);
      this.state.isForceGripDetected = true;
    } else this.state.isForceGripDetected = false;

    this.state.isBusy = false;

    // Ensure the command takes some time to complete, even when
    // there is no diameter change. This simulates the real robot better.
    await wait(TIME_INTERVAL_MS);
  }

  public stop() {
    this.state.isBusy = false;
  }

  // Implementation-specific methods

  private getDiameterMeters(): number {
    return calculateOR3FG15DiameterFromFingerAngle({
      fingerPosition: this.state.fingerPosition,
      fingerLength: this.state.fingerLength,
      fingertipDiameter: this.state.fingertipDiameter,
      angle: this.state.fingerAngle,
      gripKind: this.state.gripKind,
    });
  }

  private getFingerConfiguration() {
    return pick(this.state, [
      'fingerPosition',
      'fingertipDiameter',
      'fingerLength',
    ]);
  }

  private getForceAppliedFraction(): number {
    const { forceAppliedNewtons } = this.state;

    return (
      (forceAppliedNewtons - OR_3FG15_FORCE_APPLIED_FRACTION_MIN) /
      (OR_3FG15_FORCE_NEWTONS_MAX - OR_3FG15_FORCE_APPLIED_FRACTION_MIN)
    );
  }

  private targetDiameter = this.getDiameterMeters();

  public getModbusAddressSubscriptions() {
    return new Set([OR3FG15_TARGET_ADDRESS]);
  }

  private translateActuationTargetDiameter(args: {
    gripDirection: 'inward' | 'outward';
    kind:
      | OR3FG15_CONTROL_COMMAND.GRIP
      | OR3FG15_CONTROL_COMMAND.FLEX_GRIP
      | OR3FG15_CONTROL_COMMAND.MOVE;
    targetDiameter: number;
  }) {
    const targetDiameterMM = args.targetDiameter;

    const isGrip = args.kind !== OR3FG15_CONTROL_COMMAND.MOVE;
    const modifier = isGrip ? OR_3FG15_SOURCE_OFFSET_METERS / 1_000 : 0;

    return args.gripDirection === 'inward'
      ? targetDiameterMM + modifier
      : targetDiameterMM - modifier;
  }

  public handleModbusRegisterRequest = handleModbusRegisterRequest(
    () => this.state.isConnected,
    {
      [OR3FG15_REGISTER.TARGET_FORCE]: {
        [ModbusFunctionCode.Write]: (request) => {
          this.state.targetForceNewtons = clamp(
            // convert from 1/10th % to newtons
            (request.data * OR_3FG15_FORCE_NEWTONS_MAX) / 1_000,
            OR_3FG15_FORCE_NEWTONS_MIN,
            OR_3FG15_FORCE_NEWTONS_MAX,
          );

          return request;
        },
      },

      [OR3FG15_REGISTER.TARGET_DIAMETER]: {
        [ModbusFunctionCode.Write]: (request) => {
          // converts from 1/10th mm -> meters

          this.targetDiameter = request.data / 10_000;
          this.logger.info('Setting target diameter:', this.targetDiameter);

          return request;
        },
      },

      [OR3FG15_REGISTER.GRIP_TYPE]: {
        [ModbusFunctionCode.Write]: (request) => {
          this.state.gripKind =
            request.data === 0 ? OR_3FG15_GRIP_KINDS[0] : OR_2FG7_GRIP_KINDS[1];

          return request;
        },
      },

      [OR3FG15_REGISTER.CONTROL]: {
        [ModbusFunctionCode.Write]: (request) => {
          switch (request.data) {
            case OR3FG15_CONTROL_COMMAND.GRIP:
            case OR3FG15_CONTROL_COMMAND.FLEX_GRIP:
            case OR3FG15_CONTROL_COMMAND.MOVE:
              this.animateToTarget({
                kind: 'OnRobot3FG15Command',
                gripKind: this.state.gripKind,
                isFlexGrip: request.data === OR3FG15_CONTROL_COMMAND.FLEX_GRIP,
                waitForGripToContinue:
                  request.data === OR3FG15_CONTROL_COMMAND.GRIP,
                targetDiameter: this.translateActuationTargetDiameter({
                  gripDirection: this.state.gripKind,
                  kind: request.data,
                  targetDiameter: this.targetDiameter,
                }),
              }).catch((error) => {
                this.logger.error('Actuation error', error);
              });

              break;

            case OR3FG15_CONTROL_COMMAND.STOP:
              this.stop();
              break;

            default:
          }

          return request;
        },
      },

      [OR3FG15_REGISTER.STATUS]: {
        [ModbusFunctionCode.Read]: (request) => {
          let bits = 0;

          bits = setBit(bits, 0, this.state.isBusy ? ON : OFF);
          bits = setBit(bits, 1, this.state.isGripDetected ? ON : OFF);
          bits = setBit(bits, 2, this.state.isForceGripDetected ? ON : OFF);
          bits = setBit(bits, 3, this.state.isCalibrationOk ? ON : OFF);

          return { ...request, data: bits };
        },
      },

      [OR3FG15_REGISTER.RAW_DIAMETER]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            // diameter as 1/10th mm
            data: Math.floor(
              calculateOR3FG15DiameterFromFingerAngle({
                fingerPosition: this.state.fingerPosition,
                fingerLength: this.state.fingerLength,
                fingertipDiameter: 0,
                angle: this.state.fingerAngle,
                gripKind: this.state.gripKind,
              }) * 10_000,
            ),
          };
        },
      },

      [OR3FG15_REGISTER.DIAMETER_WITH_FINGERTIP_RADIUS]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            // diameter as 1/10th mm
            data: Math.floor(this.getDiameterMeters() * 10_000),
          };
        },
      },

      [OR3FG15_REGISTER.FORCE_APPLIED]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            // fraction as 1/10th %
            data: Math.floor(this.getForceAppliedFraction() * 1_000),
          };
        },
      },

      [OR3FG15_REGISTER.FINGER_ANGLE]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            // as 1/1000th rad
            data: Math.floor(this.state.fingerAngle * 1_000),
          };
        },
      },

      [OR3FG15_REGISTER.FINGER_LENGTH]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            // as 1/10th mm
            data: Math.floor(this.state.fingerLength * 10_000),
          };
        },
      },

      [OR3FG15_REGISTER.FINGER_POSITION]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            data: this.state.fingerPosition,
          };
        },
      },

      [OR3FG15_REGISTER.FINGERTIP_RADIUS]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            // as 1/100th mm
            data: Math.floor((this.state.fingertipDiameter / 2) * 100_000),
          };
        },
      },

      [OR3FG15_REGISTER.MAXIMUM_DIAMETER]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            // as 1/10th mm
            data: Math.floor(
              calculateOR3FG15DiameterRange({
                fingerPosition: this.state.fingerPosition,
                fingerLength: this.state.fingerLength,
                fingertipDiameter: this.state.fingertipDiameter,
                actuationKind: 'grip',
              })[this.state.gripKind].max * 10_000,
            ),
          };
        },
      },

      [OR3FG15_REGISTER.MINIMUM_DIAMETER]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            // as 1/10th mm
            data: Math.floor(
              calculateOR3FG15DiameterRange({
                fingerPosition: this.state.fingerPosition,
                fingerLength: this.state.fingerLength,
                fingertipDiameter: this.state.fingertipDiameter,
                actuationKind: 'grip',
              })[this.state.gripKind].min * 10_000,
            ),
          };
        },
      },

      [OR3FG15_REGISTER.SET_FINGER_LENGTH]: {
        [ModbusFunctionCode.Write]: (request) => {
          // convert from 1/10th mm -> meters
          this.state.fingerLength = request.data / 10_000;

          return request;
        },
      },

      [OR3FG15_REGISTER.SET_FINGER_POSITION]: {
        [ModbusFunctionCode.Write]: (request) => {
          if (isValidOR3FG16FingerPostion(request.data)) {
            // convert from 1/10th mm -> meters
            this.state.fingerPosition = request.data;
          }

          return request;
        },
      },

      [OR3FG15_REGISTER.SET_FINGERTIP_RADIUS]: {
        [ModbusFunctionCode.Write]: (request) => {
          // convert from 1/100th mm -> meters
          this.state.fingertipDiameter = (request.data * 2) / 100_000;

          return request;
        },
      },
    },
  );
}
