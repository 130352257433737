export function roundToDecimalPlaces(value: number, decimalDigits: number) {
  const roundingFactor = 10 ** decimalDigits;

  // round to fixed number of decimal digits (avoids floating point decimal rounding issues)
  const result = Math.round(value * roundingFactor) / roundingFactor;

  // return 0 when result is -0
  return result === 0 ? 0 : result;
}

const DECIMAL_DIGITS = /\.(\d+)$/;

/**
 * Number of decimal digits in step (e.g. 0.25 -> 2; 0.1 -> 1)
 */
export function countDecimalPlaces(value: number): number {
  return String(value).match(DECIMAL_DIGITS)?.[1].length ?? 0;
}
