import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import type { Robot, Unsubscribe } from '@sb/types';

import {
  convertEquipmentResponse,
  getEquipmentService,
} from '../equipment/utils';
import { getSafetySettingsService } from '../safety-settings/utils';
import { cleanQuery, mapPaginated, retryOnTimeout } from '../utils';

import { convertRobotResponse, getRobotsService } from './utils';

interface OnGetRobotsArguments {
  robotID?: string;
  isDefault?: true;
  routineID?: string | null;
}

export function onGetRobots(
  { robotID, isDefault, routineID }: OnGetRobotsArguments,
  onSnapshot: (robots: Robot.ConvertedResponse[]) => void,
): Unsubscribe {
  const robot$ = getRobotsService()
    .watch()
    .find({
      query: cleanQuery({
        robotID,
        isDefault,
        latestRoutineID: routineID ?? undefined,
        $sort: { name: 1 },
      }),
    })
    .pipe(retryOnTimeout());

  const safeguardRules$ = getSafetySettingsService()
    .watch()
    .find({ $select: ['robotID', 'safeguardRules'] })
    .pipe(
      retryOnTimeout(),
      map((response) => mapPaginated(response, (r) => r)),
    );

  const equipment$ = getEquipmentService()
    .watch()
    .find({ query: { isEnabled: true, $sort: { updatedAt: -1 } } })
    .pipe(
      retryOnTimeout(),
      map((response) => mapPaginated(response, convertEquipmentResponse)),
    );

  const subscription = combineLatest([
    robot$,
    safeguardRules$,
    equipment$,
  ]).subscribe(([robotResponse, safeguardRules, equipment]) => {
    const robots = mapPaginated(
      robotResponse,
      convertRobotResponse(
        (findRobotID) =>
          safeguardRules.find((s) => s.robotID === findRobotID)
            ?.safeguardRules ?? [],
        equipment,
      ),
    );

    onSnapshot(robots);
  });

  return () => subscription.unsubscribe();
}
