interface FeatureFlags {
  adHocFullSpeed: boolean;
  dualModeCollision: boolean;
  enableScrewdriverPickup: boolean;
  enableDeveloperAPI: boolean;
  forceFeedback: boolean;
  guidedRoutines: boolean;
  welcomeGuides: boolean;
  safetyIO: boolean;
  webRTC: boolean;
  camera: boolean;
  config2FG7: boolean;
  enableCustomTCP: boolean;
  cacheMotionPlanRequests: boolean;
  cacheMotionPlans: boolean;
  runInBackgroundStep: boolean;
  environmentObjects: boolean;
}

const getFlagUrlValue = (key: string) => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search).get(key);
  }

  return null;
};

const getLocalStorageFeatureFlagKey = (key: string) => `featureFlag:${key}`;

const getLocalStorageItem = (key: string): string | null => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(getLocalStorageFeatureFlagKey(key));
  }

  return null;
};

const setLocalStorageItem = (key: string, value: string) => {
  if (typeof window !== 'undefined') {
    return window.localStorage.setItem(
      getLocalStorageFeatureFlagKey(key),
      value,
    );
  }

  return null;
};

const getFlag = (key: string) => {
  const localStorageFlag = getLocalStorageItem(key);
  const value = getFlagUrlValue(key);

  const override = value === 'on' || value === 'off';

  if (override && localStorageFlag !== value) {
    setLocalStorageItem(key, value);
  }

  if (value === null) {
    return localStorageFlag === 'on';
  }

  return value !== 'off';
};

export const flagValues: Readonly<FeatureFlags> = {
  adHocFullSpeed: getFlag('adHocFullSpeed'),
  config2FG7: getFlag('config2FG7'),
  dualModeCollision: getFlag('dualModeCollision'),
  enableScrewdriverPickup: getFlag('enableScrewdriverPickup'),
  enableDeveloperAPI: getFlag('enableDeveloperAPI'),
  forceFeedback: getFlag('forceFeedback'),
  guidedRoutines: getFlag('guidedRoutines'),
  safetyIO: getFlag('safetyIO'),
  webRTC: getFlag('webRTC'),
  welcomeGuides: getFlag('welcomeGuides'),
  camera: getFlag('camera'),
  enableCustomTCP: getFlag('enableCustomTCP'),
  cacheMotionPlanRequests: getFlag('cacheMotionPlanRequests'),
  cacheMotionPlans: getFlag('cacheMotionPlans'),
  runInBackgroundStep: getFlag('runInBackgroundStep'),
  environmentObjects: getFlag('environmentObjects'),
};

// TODO SW-2141 feature flags will be persisted in db
/**
 * Local feature flags - use ?flag='on' or ?flag='off' in url to
 * save the feature flag to local storage
 * @param flag the flag to check
 * @returns the value of the flag
 */
export function useFeatureFlag<F extends keyof FeatureFlags>(
  flag: F,
): FeatureFlags[F] {
  return flagValues[flag];
}
