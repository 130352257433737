import * as zod from 'zod';

import { ModbusTCPRegisterData } from './types';

export const ModbusTCPServerConfiguration = zod.object({
  kind: zod.literal('ModbusTCPServer'),
  name: zod.string().optional(),
  connectionOptions: zod.object({
    host: zod.string(),
    port: zod.number(),
    timeoutMS: zod.number(),
  }),
  registers: zod.array(ModbusTCPRegisterData).optional(),
});

export type ModbusTCPServerConfiguration = zod.infer<
  typeof ModbusTCPServerConfiguration
>;
