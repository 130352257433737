import type { ArmJointPositions } from '@sb/motion-planning';
import {
  convertDegreesToRadians,
  convertRadiansToDegrees,
  roundToDecimalPlaces,
} from '@sb/utilities';

function getAngleDegrees(radians: number): number {
  return roundToDecimalPlaces(convertRadiansToDegrees(radians), 1);
}

export const convertJointAngles = {
  /**
   * Convert joint angles in radians to degrees with 1 decimal place for display in the UI
   */
  toDegrees(jointAngles: ArmJointPositions): ArmJointPositions {
    return [
      getAngleDegrees(jointAngles[0]),
      getAngleDegrees(jointAngles[1]),
      getAngleDegrees(jointAngles[2]),
      getAngleDegrees(jointAngles[3]),
      getAngleDegrees(jointAngles[4]),
      getAngleDegrees(jointAngles[5]),
    ];
  },

  /**
   * Convert joint angles in degrees to radians
   */
  fromDegrees(jointAngles: ArmJointPositions): ArmJointPositions {
    return [
      convertDegreesToRadians(jointAngles[0]),
      convertDegreesToRadians(jointAngles[1]),
      convertDegreesToRadians(jointAngles[2]),
      convertDegreesToRadians(jointAngles[3]),
      convertDegreesToRadians(jointAngles[4]),
      convertDegreesToRadians(jointAngles[5]),
    ];
  },
};
