import * as zod from 'zod';

export const ModbusTCPServerRegisterKind = zod.union([
  zod.literal('inputRegister'),
  zod.literal('holdingRegister'),
  zod.literal('inputCoil'),
  zod.literal('statusCoil'),
]);

export type ModbusTCPServerRegisterKind = zod.infer<
  typeof ModbusTCPServerRegisterKind
>;

export const ModbusTCPServerRegisterFormat = zod.union([
  // coils are always 1 bit
  zod.literal('bit'),

  zod.literal('UInt16BE'),
  zod.literal('Int16BE'),
  zod.literal('UInt16LE'),
  zod.literal('Int16LE'),

  zod.literal('UInt32BE'),
  zod.literal('Int32BE'),
  zod.literal('UInt32LE'),
  zod.literal('Int32LE'),

  zod.literal('UInt64BE'),
  zod.literal('Int64BE'),
  zod.literal('UInt64LE'),
  zod.literal('Int64LE'),
]);

export type ModbusTCPServerRegisterFormat = zod.infer<
  typeof ModbusTCPServerRegisterFormat
>;

export const ModbusTCPRegisterData = zod.object({
  id: zod.string(),
  name: zod.string(),
  type: ModbusTCPServerRegisterKind,
  offset: zod.string(),
  format: ModbusTCPServerRegisterFormat,
});

export type ModbusTCPRegisterData = zod.infer<typeof ModbusTCPRegisterData>;
