import { Logger } from '@sb/logger';
import type { ArmJointPositions, ArmTarget } from '@sb/motion-planning';
import type { RoutineContext } from '@sb/routine-runner/RoutineContext';
import type { EventEmitter } from '@sb/utilities';

/**
 * This class saves the joint angles whenever there is a pause event on the
 * event emitter.  When getUpdatedTargets is called, it will add the saved position
 * as the first point to the target list.  This is to solve the issue when where
 * the arm resumes from a position that is not the last original motion plan.
 * With this change, the arm will go back to the last position before the pause
 * then continue with the original motion plan.
 */
export class PausedStatePositionSaver {
  private events: EventEmitter<any>;

  private jointAngles: ArmJointPositions | undefined;

  private routineContext: RoutineContext;

  private pauseListener: () => void | undefined;

  private logger: Logger = new Logger();

  constructor({
    events,
    routineContext,
  }: {
    events: EventEmitter<any>;
    routineContext: RoutineContext;
  }) {
    this.logger.enableConsole();
    this.logger.label = 'PausedStatePositionSaver';

    this.events = events;
    this.routineContext = routineContext;

    this.pauseListener = this.events.on('pause', () => {
      this.logger.info(
        'setting saved position to ',
        this.routineContext.getRoutineRunnerState().kinematicState.jointAngles,
      );

      this.jointAngles = [
        ...this.routineContext.getRoutineRunnerState().kinematicState
          .jointAngles,
      ];
    });
  }

  public destroy() {
    this.pauseListener();
  }

  getUpdatedTargets(targets: ArmTarget[]): ArmTarget[] {
    if (!this.jointAngles) {
      return targets;
    }

    this.logger.info(
      'resuming from last joint angles:',
      this.jointAngles,
      'current:',
      this.routineContext.getRoutineRunnerState().kinematicState.jointAngles,
    );

    const pausedTarget: ArmTarget = {
      motionKind: 'joint',
      jointAngles: this.jointAngles,
    };

    return [pausedTarget, ...targets];
  }
}
