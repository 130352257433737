import type * as zod from 'zod';

import {
  applyCompoundPose,
  invertPose,
  tooltipCoordinatesToBaseCoordinates,
} from '@sb/geometry';
import { ArmTarget } from '@sb/motion-planning';

import { FailureKind } from '../../FailureKind';
import type { RoutineContext } from '../../RoutineContext';
import type { StepFailure } from '../../types';
import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class MoveArmToStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    const { completedCount = 0 } = this.variablesForInitialization;

    this.variables = {
      completedCount,
      currentActivity: 'none',
    };
  }

  private motion?: ReturnType<RoutineContext['doMotion']>;

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    if (this.variables.currentActivity !== 'none') {
      // cannot play unless the step is doing nothing currently
      throw new Error('cannot double play MoveArmToStep');
    }

    const target = await this.getArmTarget();

    if ('failure' in target) {
      return fail(target);
    }

    if (this.args.isWaypoint) {
      this.routineContext.pushWaypoint({
        armTarget: target,
        blend: this.args.blend,
      });

      return;
    }

    const motion = await this.routineContext.doMotion(
      { targets: [target], isCacheable: this.args.isCacheable },
      this.args.speedProfile,
      this.args.pushingCollisionThresholds,
      this.id,
    );

    motion.on('requestingPlan', () => {
      if (this.variables.currentActivity === 'none') {
        this.setVariable('currentActivity', 'requestingPlan');
      }
    });

    motion.on('planning', () => {
      if (this.variables.currentActivity === 'requestingPlan') {
        this.setVariable('currentActivity', 'planning');
      }
    });

    motion.on('beginMotion', () => {
      this.setVariable('currentActivity', 'moving');
    });

    motion.on('pause', () => {
      this.setVariable('currentActivity', 'paused');
    });

    motion.on('complete', () => {
      this.setVariable('completedCount', this.variables.completedCount + 1);
    });

    motion.on('failure', (failure) => fail(failure));

    this.motion = motion;

    await motion.race('failure', 'complete');

    this.setVariable('currentActivity', 'none');
    motion.removeAllListeners();

    delete this.motion;
  }

  public _pause(): void {
    if (this.motion) {
      this.motion.emit('pause');
    }
  }

  public _resume(): void {
    if (this.motion) {
      this.motion.emit('resume');
    }
  }

  public _stop() {
    if (this.motion) {
      this.motion.emit('cancel');
    }
  }

  private async getArmTarget(): Promise<ArmTarget | StepFailure> {
    const parseTarget = (armPosition: any) => {
      const target = {
        motionKind: this.args.motionKind,
        ...armPosition,
      };

      if (this.args.shouldMatchJointAngles) {
        target.pose = undefined;
      } else {
        target.jointAngles = undefined;
      }

      const parsedTarget = ArmTarget.safeParse(target);

      if (!parsedTarget.success) {
        throw new Error('Move arm target is not valid');
      }

      if (!this.args.shouldMatchJointAngles && 'pose' in parsedTarget.data) {
        parsedTarget.data.pose = applyCompoundPose(
          invertPose(
            tooltipCoordinatesToBaseCoordinates(this.args.tooltipPose),
          ),
          parsedTarget.data.pose,
        );
      }

      return parsedTarget.data;
    };

    try {
      if ('positionListID' in this.args.target) {
        return parseTarget(
          this.routineContext.getPositionListEntry(
            this.args.target.positionListID,
            this.variables.completedCount,
          ),
        );
      }

      if ('expression' in this.args.target) {
        const expressionTarget = await this.routineContext.evaluateExpression(
          this.args.target.expression,
        );

        // if expression evaluates to an array, use the first item
        // this makes it easier to use 'SinglePosition' space objects.
        return parseTarget(
          Array.isArray(expressionTarget)
            ? expressionTarget[0]
            : expressionTarget,
        );
      }

      return parseTarget(this.args.target);
    } catch (error) {
      return {
        failure: {
          kind: FailureKind.InvalidRoutineLoadedFailure,
        },
        failureReason: error.message,
        error,
      };
    }
  }

  public hasSelfTimeout(): boolean {
    return false;
  }
}
