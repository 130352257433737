import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Logger, ConsoleBackend } from '@sb/logger';
import { RoutineRunner } from '@sb/routine-runner';
import { SingleRobotSimulator } from '@sb/simulator';
import SimulatorPacketReceiver from '@sb/simulator/SimulatorPacketReceiver';
import type { Notification, User } from '@sb/types';
import { Toast } from '@sb/ui/components';
import type { ToastState } from '@sb/ui/components';
import { GlobalContext } from '@sbrc/contexts';
import { useAuthState } from '@sbrc/hooks';
import {
  getCurrentUserID,
  getMotionPlanner,
  jsRunner,
  onGetNotifications,
  onGetUser,
  VizbotRoutineRunnerHandle,
} from '@sbrc/services';

interface AppProviderProps {
  children: React.ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
  const { route } = useRouter();

  const { userID } = useAuthState();

  const [toast, setToast] = useState<ToastState | null>(null);
  const [settings, setSettings] = useState<User.ConvertedResponse | null>();

  const [visualizerChildren, setVisualizerChildren] = useState<
    Record<string, React.ReactNode>
  >({});

  const [vizbotRoutineRunnerHandle, setVizbotRoutineRunnerHandle] = useState<
    VizbotRoutineRunnerHandle | undefined
  >();

  const [notifications, setNotifications] = useState<
    Notification.ConvertedResponse[]
  >([]);

  useEffect(() => {
    const handle = new VizbotRoutineRunnerHandle();

    const simulator = new SingleRobotSimulator();

    const routineRunner = new RoutineRunner({
      motionPlanner: getMotionPlanner(),
      robot: simulator,
      inference: simulator,
      camera: simulator.camera,
      jsRunner,
      equipment: simulator.equipment,
    });

    const logger = new Logger();
    logger.label = `Vizbot ${simulator.debugUUID}`;
    logger.addContext('userID', getCurrentUserID() ?? '<unknown>');
    logger.addBackend(new ConsoleBackend());
    logger.info('Vizbot created');
    handle.logger = logger;

    const receiver: SimulatorPacketReceiver = new SimulatorPacketReceiver(
      simulator,
      routineRunner,
    );

    receiver.onPacket((packet) => {
      handle.receivePacket(packet);
    });

    handle.onPacket((packet) => {
      receiver.receivePacket(packet);
    });

    simulator.unbrake();

    setVizbotRoutineRunnerHandle(handle);

    return () => {
      setVizbotRoutineRunnerHandle(undefined);

      // the handle will still be provided in context until the next render,
      // so delay destroying until the next tick
      setTimeout(() => {
        handle.destroyHandle();
        routineRunner.destroy();
        receiver.destroyHandle();
      });
    };
  }, [route]);

  useEffect(() => {
    setSettings(undefined);

    if (!userID) {
      return undefined;
    }

    const unsubscribe = onGetUser(userID, setSettings);

    return () => unsubscribe();
  }, [userID]);

  // Get all notifications
  useEffect(() => {
    if (!userID) {
      setNotifications([]);

      return undefined;
    }

    const unsubscribe = onGetNotifications(userID, setNotifications);

    return () => unsubscribe();
  }, [userID]);

  return (
    <GlobalContext.Provider
      value={{
        notifications,
        settings,
        toast: { state: toast, set: setToast },
        visualizerChildren: {
          state: visualizerChildren,
          set: setVisualizerChildren,
        },
        userID,
        vizbotRoutineRunnerHandle,
      }}
    >
      {children}

      <Toast
        isOpen={Boolean(toast && !toast.isClosed)}
        kind={toast?.kind}
        onClose={() => setToast(toast && { ...toast, isClosed: true })}
      >
        {toast?.message}
      </Toast>
    </GlobalContext.Provider>
  );
};

export default AppProvider;
