import type { Equipment, Integrations } from '@sb/types';

import type { DeviceConfiguration, DeviceKind } from '../device';
import type { DualGripperCompatibleGrippers } from '../OnRobotDualQuickChanger/types/Configuration';
import { isEndEffector } from '../util';

export const getEquipmentList = (): DeviceKind[] => [
  'OnRobot3FG15',
  'OnRobot2FG7',
  'OnRobotScrewdriver',
  'EwellixLiftTLT',
  'OnRobotDualQuickChanger',
  'OnRobotVGP20',
  'CustomIO',
  'ModbusTCPServer',
];

const onRobotDualCompatableEndEffectors = [
  'OnRobot3FG15',
  'OnRobot2FG7',
  'NoGripper',
] as DualGripperCompatibleGrippers['kind'][];

export const isDualCompatibleEndEffector = (kind: DeviceKind) => {
  return onRobotDualCompatableEndEffectors.some((k) => k === kind);
};

export const getEndEffector = (equipment: DeviceConfiguration[]) => {
  return equipment.find((item) => isEndEffector(item.kind));
};

export const canAddIntegration = (
  kind: DeviceKind,
  existingEquipment: Equipment.ConvertedResponse[],
  getEquipmentByKind: Integrations.GetEquipmentByKind,
): boolean => {
  const proposedIntegration = getEquipmentByKind(kind);

  if (proposedIntegration.canAddAsNewIntegration) {
    const existingIntMapped = existingEquipment
      .filter((item) => item.isEnabled)
      .map((item) => getEquipmentByKind(item.config.kind));

    return proposedIntegration.canAddAsNewIntegration(existingIntMapped);
  }

  return true;
};

export const getOnRobotDualCompatableEndEffectorsList =
  (): DualGripperCompatibleGrippers['kind'][] => {
    return onRobotDualCompatableEndEffectors;
  };
