import { useEffect, useRef } from 'react';

type ListenEvent = MouseEvent | TouchEvent;
type EventHandler = (event: ListenEvent) => void;

export function useClickOutside(
  refs: Array<HTMLElement | null | React.RefObject<HTMLElement>>,
  handler: EventHandler | undefined,
) {
  // store the element refs in another ref, to avoid event listener teardown/setup on every render
  const refsRef = useRef(refs);

  useEffect(() => {
    refsRef.current = refs;
  });

  useEffect(() => {
    if (!handler) {
      return undefined;
    }

    const listener = (event: ListenEvent) => {
      // check the click wasn't on any of the elements or their descendents
      const isClickAway = refsRef.current.every((elementOrRef) => {
        const element =
          elementOrRef && 'current' in elementOrRef
            ? elementOrRef.current
            : elementOrRef;

        return !element || !element.contains(event.target as Node);
      });

      if (isClickAway) {
        handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [handler]);
}
