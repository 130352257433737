import * as zod from 'zod';

import { ZERO_ROTATION } from '@sb/geometry';
import { convertDegreesToRadians } from '@sb/utilities';

export const OR3FG15FingerPosition = zod.union([
  zod.literal(1),
  zod.literal(2),
  zod.literal(3),
]);

export const OnRobot3FG15Configuration = zod.object({
  fingerPosition: OR3FG15FingerPosition,
  fingertipDiameter: zod.number().positive(),
});

export type OnRobot3FG15Configuration = zod.infer<
  typeof OnRobot3FG15Configuration
>;

export const OR_3FG15_COMMAND_KIND_DEFAULT: OR3FG15GripKind = 'inward';

export const OR_3FG15_FINGER_POSITION_DEFAULT: OR3FG15FingerPosition = 2;

/**
 * It uses the maximum finger angle. We use this value to calculate the diameter and
 * we're using this value here to ensure the simulator uses a valid diameter on start.
 */
export const OR_3FG15_FINGER_ANGLE_MAX = convertDegreesToRadians(30);

export const OR_3FG15_DIAMETER_METERS_SLIDER_STEP = 0.001;
export const OR_3FG15_DIAMETER_MM_SLIDER_STEP = 1;
export const OR_3FG15_FORCE_NEWTONS_SLIDER_STEP = 1;
export const DEFAULT_OR_3FG15_DIAMETER_TOLERANCE_METERS = 0.01;

export const OR_3FG15_FINGERTIP_DIAMETER_DEFAULT = 0.0135;
export const OR_3FG15_TARGET_FORCE_DEFAULT = 45; // Newton equivalent of 10 lbs

export const OR_3FG15_CONFIGURATION_DEFAULT: OnRobot3FG15Configuration = {
  fingerPosition: OR_3FG15_FINGER_POSITION_DEFAULT,
  fingertipDiameter: OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
};

/**
 * The robot can't get to the exact position provided by the routine-runner because of rounding issues.
 * So, we are calculating the approximate variance using the `DIAMETER_TOLERANCE_METERS` value
 * we have in `./implementation`, which is `0.003`.
 */
export const OR_3FG15_DIAMETER_EPSILON = 0.003;

export const OR_3FG15_FORCE_NEWTONS_MIN = 10;
export const OR_3FG15_FORCE_NEWTONS_MAX = 240;
export const OR_3FG15_FLEX_GRIP_FORCE_NEWTONS_MAX = 140;

export const OR_3FG15_FORCE_APPLIED_FRACTION_MIN = 0;
export const OR_3FG15_FORCE_APPLIED_FRACTION_MAX = 1;

export const OR_3FG15_DEFAULT_FINGER_LENGTH = 0.049;
export const OR_3FG15_OFFSETS = {
  1: 0.0355,
  2: 0.0445,
  3: 0.0535,
} as const;
export const OR_3FG15_TO_FINGER_OFFSET = 0.03;

export const OR_3FG15_MOVE_JOINT_MAX = 0;
export const OR_3FG15_MOVE_JOINT_MIN = Math.PI;

/**
 * DO NOT CHANGE: Diameter offset "3" as seen in OR compute box
 * source code to calculate actual diameter
 */
export const OR_3FG15_SOURCE_OFFSET_METERS = 3;

/**
 * inward grip is internal language; OnRobot uses 'external grip' (smaller range)
 * outward grip is internal language; OnRobot uses 'internal grip' (wider range)
 */
export const OR_3FG15_GRIP_KINDS = ['inward', 'outward'] as const;

export const OR_3FG15_COMMAND_KINDS = ['move', 'grip', 'flexGrip'] as const;

/**
 *  Angle for external gripping min 165º (Pos 1), 163 º (Pos 2), 161 º (Pos 3) and max 30º (all 3 positions)
 * https://onrobot.com/sites/default/files/documents/Datasheet_3FG15_v1.4_EN.pdf
 */

export const OR_3FG15_POSITION_1_INWARD_GRIP_MIN_ANGLE = 165;
export const OR_3FG15_POSITION_2_INWARD_GRIP_MIN_ANGLE = 163;
export const OR_3FG15_POSITION_3_INWARD_GRIP_MIN_ANGLE = 161;

export const OR3FG15GripKind = zod.enum(OR_3FG15_GRIP_KINDS);

export const OR3FG15CommandKind = zod.enum(OR_3FG15_COMMAND_KINDS);

export type OR3FG15GripKind = zod.infer<typeof OR3FG15GripKind>;

export type OR3FG15CommandKind = zod.infer<typeof OR3FG15CommandKind>;

export const OR_3FG15_VALID_FINGER_POSITIONS = [1, 2, 3] as const;

export function isValidOR3FG16FingerPostion(
  value: any,
): value is typeof OR_3FG15_VALID_FINGER_POSITIONS[number] {
  return OR_3FG15_VALID_FINGER_POSITIONS.includes(value);
}

export type OR3FG15FingerPosition = zod.infer<typeof OR3FG15FingerPosition>;

// Specified on page 70 of the 3FG15 documentation.
// Documentation says it would be 0x42 (primary side) or 0x43 (secondary side)
// for dual quick changer.
export const OR3FG15_TARGET_ADDRESS = 0x41;

/**
 * These can be found on page 72 of the 3FG15 documentation
 *
 * "offset", "diameter", and "size" seem to mean the same thing in the context
 * of fingertips according to the documentation.
 *
 * HOWEVER, when playing with the real OR3FG15, it appears it halves the fingertip
 * offset as compared to what the diameter would imply. Therefore, we rename it "radius"
 * here.
 */
export enum OR3FG15_REGISTER {
  // Write
  TARGET_FORCE = 0x0000,
  TARGET_DIAMETER = 0x0001,
  GRIP_TYPE = 0x0002,
  CONTROL = 0x0003,

  // Read only
  STATUS = 0x0100,
  RAW_DIAMETER = 0x0101,
  DIAMETER_WITH_FINGERTIP_RADIUS = 0x0102,
  FORCE_APPLIED = 0x0103,
  FINGER_ANGLE = 0x0105,
  FINGER_LENGTH = 0x010e,
  FINGER_POSITION = 0x0110,
  FINGERTIP_RADIUS = 0x0111,
  MINIMUM_DIAMETER = 0x0201,
  MAXIMUM_DIAMETER = 0x0202,

  // Read/write
  SET_FINGER_LENGTH = 0x0401,
  SET_FINGER_POSITION = 0x0403,
  SET_FINGERTIP_RADIUS = 0x0404,
}

export enum OR3FG15_CONTROL_COMMAND {
  GRIP = 0x01,
  FLEX_GRIP = 0x05,
  MOVE = 0x02,
  STOP = 0x04,
}

export const OR3FG15_DEFAULT_TOOLTIP_POSE = {
  ...ZERO_ROTATION,
  x: 0,
  y: 0,
  z: 0.156,
};
