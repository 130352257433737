import { isEqual } from 'lodash';

import type { ArmJointLimits } from '@sb/motion-planning';
import type { RoutineRunnerState } from '@sb/routine-runner';
import { convertDegreesToRadians } from '@sb/utilities';
import { convertJointAngles } from '@sbrc/utils';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

/**
 * The tolerance for the joint angle to be considered at min or max of a joint limit
 * Since motion planner has a 0.5 degree step when doing a relative joint motion, the end
 * angle can be up to 0.5 off from the actual joint limit. See [ModelonePlanningInterface::computeLongestJointLine](https://github.com/standardbots/sb/blob/main/apps/motion-planner-bot/src/modelone_moveit_interface/src/modelone_moveit_interface.cpp#L2078)
 */
const JOINT_LIMIT_DIFF_RADS = convertDegreesToRadians(0.5);

function getJointLimitsDegrees(
  state: RoutineRunnerState | null,
): ArmJointLimits | null {
  if (!state) {
    return null;
  }

  const { jointLimits } = state.kinematicState;

  // avoiding `.map` to improve performance

  const mins = convertJointAngles.toDegrees([
    jointLimits[0].min + JOINT_LIMIT_DIFF_RADS,
    jointLimits[1].min + JOINT_LIMIT_DIFF_RADS,
    jointLimits[2].min + JOINT_LIMIT_DIFF_RADS,
    jointLimits[3].min + JOINT_LIMIT_DIFF_RADS,
    jointLimits[4].min + JOINT_LIMIT_DIFF_RADS,
    jointLimits[5].min + JOINT_LIMIT_DIFF_RADS,
  ]);

  const maxs = convertJointAngles.toDegrees([
    jointLimits[0].max - JOINT_LIMIT_DIFF_RADS,
    jointLimits[1].max - JOINT_LIMIT_DIFF_RADS,
    jointLimits[2].max - JOINT_LIMIT_DIFF_RADS,
    jointLimits[3].max - JOINT_LIMIT_DIFF_RADS,
    jointLimits[4].max - JOINT_LIMIT_DIFF_RADS,
    jointLimits[5].max - JOINT_LIMIT_DIFF_RADS,
  ]);

  return [
    { min: mins[0], max: maxs[0] },
    { min: mins[1], max: maxs[1] },
    { min: mins[2], max: maxs[2] },
    { min: mins[3], max: maxs[3] },
    { min: mins[4], max: maxs[4] },
    { min: mins[5], max: maxs[5] },
  ];
}

/**
 * Get the joint limits in degrees to 1 decimal place
 */
export function useRobotJointLimitsDegrees(
  args: UseRoutineRunnerHandleArguments,
): ArmJointLimits | null {
  return useRobotState(
    args,
    getJointLimitsDegrees,
    // it is valid to use `isEqual` to compare values because they have been rounded to 1dp
    isEqual,
  );
}
