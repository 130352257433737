import type { Severity, Formatter } from '../types';
import { LogSinkFormat } from '../types';

import * as browser from './browser';
import * as json from './json';
// import * as splunk from './splunk';
import * as stdout from './stdout';

const detectPlatform = (() => {
  try {
    // eslint-disable-next-line global-require
    return require('detect-browser').detect;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('detect browser not installed or not supported');

    return false;
  }
})();

let logSinkFormat = (() => {
  if (process.env.NODE_ENV === 'production') return LogSinkFormat.Splunk;
  if (detectPlatform()?.name === `node`) return LogSinkFormat.Stdout;

  return LogSinkFormat.Browser;
})();

const logSinkToFormatter = {
  // TODO (joe): Migrate back to splunk formatter once we
  // verify w/ splunk how we should be formatting logs
  [LogSinkFormat.Splunk]: json.formatter,
  [LogSinkFormat.Stdout]: stdout.formatter,
  [LogSinkFormat.Browser]: browser.formatter,
  [LogSinkFormat.Json]: json.formatter,
} as const;

export const getLogSinkFormat = () => logSinkFormat;

export const setLogSinkFormat = (format: LogSinkFormat) => {
  logSinkFormat = format;
};

const getFormatter = (format: LogSinkFormat): Formatter => {
  return logSinkToFormatter[format];
};

export const format: Formatter = (
  severity: Severity,
  key: string,
  message: string,
  context: any,
): string[] | string => {
  return getFormatter(logSinkFormat)(severity, key, message, context);
};
